import styled from 'styled-components'

import { heading } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const Team = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  grid-gap: 9rem;
  margin-top: -8rem;

  ${mq.medium} {
    grid-template-columns: minmax(33%, 25rem) 1fr;
    grid-gap: 6rem;
    align-items: stretch;
    margin-top: 0;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  ${mq.large} {
    grid-template-columns: 43.6rem 1fr;
    padding-right: 8rem;
    grid-gap: 11rem;

    .gatsby-image-wrapper {
      height: auto;
    }
  }
`

export const Name = styled.p`
  ${heading.xs};
  margin-top: 2em;
`

export const CircleContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  left: 50%;
  margin: -5.7rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11.4rem;
  padding: 3px;
  border-radius: 50%;
  background: white;

  ${mq.medium} {
    font-size: 14rem;
    margin: -7rem;
  }

  ${mq.large} {
    right: 0;
    left: auto;
    margin: -4rem;
    font-size: 16rem;
  }
`

export const ScoreHeading = styled.span<{ color: string }>`
  ${heading.xs};
  display: block;
  margin-bottom: 1.8em;
  text-align: center;
  font-size: 0.4em;
  text-transform: uppercase;
  color: ${props => props.color};
  text-size-adjust: none;

  ${mq.medium} {
    font-size: 0.6em;
    margin-bottom: 1.4em;
  }
`

export const Score = styled.div`
  ${heading.xs};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 2.4em;
  line-height: 1;

  span {
    display: block;
    color: ${theme.color.border.blue};
    font-size: 0.34em;

    &:before {
      content: '';
      display: block;
      border-bottom: 1px solid currentColor;
      margin: 0.1em 0 0.6em;

      ${mq.medium} {
        margin: 0.16em 0 0.8em;
      }
    }

    span {
      display: block;
      font-size: 0;

      &:before {
        display: none;
      }
    }
  }
`

export const TeamExpandContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  padding: 0 3rem;
  transform: translate(-50%, 50%);
  z-index: 1;

  ${mq.medium} {
    width: auto;
    padding: 0;
  }

  span {
    display: block;
    margin: 0.1em -0.9em -0.1em -0.9em;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin: -0.5em 0.4em -0.4em -0.2em;
    font-size: 1.5em;
  }
`

export const MeetTheTeam = styled.div`
  margin-top: 6rem;

  ${mq.medium} {
    margin-top: 14rem;
  }
`
