import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Container from 'components/container'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Icon from 'components/icons/sperm-with-stars'
import AboutTeam from './team'
import {
  Header,
  Headline,
  Section,
  SectionTitle,
  Paragraph,
  Img,
  Intro,
  ImgContainer,
  IconFooterCTA,
} from './styled'
import { ButtonLink } from 'components/button'
import urls from 'common/urls'

type QueryResult = {
  intro: FluidImage
  introMedium: FluidImage
  advisors: { nodes: Member[] }
}

const query = graphql`
  query {
    intro: file(relativePath: { eq: "story-intro.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    introMedium: file(relativePath: { eq: "story-intro-medium.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 826) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    advisors: allTeamJson(filter: { is_advisor: { in: [true] } }) {
      nodes {
        ...teamFields
      }
    }
  }
`

const About = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Layout>
      <SEO title="Our Story" />
      <Header>
        <Container>
          <Headline>We’re rethinking the future of men’s fertility.</Headline>
        </Container>
      </Header>

      <Section hasBackground>
        <Container>
          <Intro>
            <div>
              <SectionTitle>
              <span translate="no">Fellow </span>men, it&apos;s time to join the conversation.
              </SectionTitle>

              <Paragraph>
                Did you know that men and women experience fertility issues in
                equal numbers? But when it comes to testing, most guys don’t.
                And, even when they do, their options are either outrageously
                expensive, super uncomfortable, or downright confusing. Starting
                with seamless fertility testing, we’re working to broaden the
                cultural conversations around reproductive health, making them
                more inclusive and invigorating for all. We’re getting
                proactive, and bringing fertility to the forefront of
                discussions about men’s health. Because when it comes down to
                it, men have half the responsibility—and half the opportunity.
                So quit having awkward conversations with your wife’s doctor, or
                worse, suffering in silence wondering if you’re “the problem.”
                It’s time to feel empowered, no matter where you are in your
                fertility journey. Enter:<span translate="no"> Fellow</span>.
              </Paragraph>
            </div>
            <ImgContainer>
              {/* @ts-expect-error */}
              <Img medium={false} fluid={data.intro.childImageSharp.fluid} />
              {/* @ts-expect-error */}
              <Img medium fluid={data.introMedium.childImageSharp.fluid} />
            </ImgContainer>
          </Intro>
        </Container>
      </Section>

      <AboutTeam />

      <Section hasBackground>
        <Container>
          <IconFooterCTA>
            <Icon />
            <div>
              <SectionTitle>
                We made our product with top doctors and new tech.
              </SectionTitle>
              <Paragraph>
                We obsess over the science and the details to give you a
                best-in-class experience. Learn about the doctors who helped
                shape our product and our scientific credibility.
              </Paragraph>
              <br />
              <ButtonLink to={urls.science}>View Our Science</ButtonLink>
            </div>
          </IconFooterCTA>
        </Container>
      </Section>
    </Layout>
  )
}

export default About
