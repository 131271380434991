import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Container from 'components/container'
import { Section, SectionTitle, Paragraph, Img, ImgContainer } from '../styled'
import { Team, Name } from './styled'

type QueryResult = {
  will: FluidImage
  team: {
    nodes: readonly Member[]
  }
}

const query = graphql`
  query {
    will: file(relativePath: { eq: "will.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 436) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const AboutTeam = () => {
  const { will } = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Container>
        <Team>
          <ImgContainer>
            {/* @ts-expect-error */}
            <Img fluid={will.childImageSharp.fluid} />
          </ImgContainer>
          <div>
            <SectionTitle indent>
              “I tested my fertility early, and decided my goal was to make it
              easier for you than it was for me.”
            </SectionTitle>

            <Paragraph>
              Men typically know very little about their fertility, and that’s
              something we are determined to change at<span translate="no"> Fellow</span>. We wake up every
              day set on enabling our customers to “make it happen.” Whether
              that means learning more about your body, having a baby, or
              checking on the success of a vasectomy, we’re here to empower you
              through knowledge. Our goal is to destigmatize reproductive health
              and improve access to clinical insights, and that’s the experience
              you should expect at <span translate="no"> Fellow</span>.
            </Paragraph>
            <Name>Will Matthews, Founder and CEO</Name>
          </div>
        </Team>
      </Container>
    </Section>
  )
}

export default AboutTeam
